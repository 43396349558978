import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useHref } from '@wix/tpa-router/react';

import {
  groupActionClick,
  groupsRequestJoinAGroup,
} from '@wix/bi-logger-groups/v2';
import { groupsSearchOpenSearchSrc111Evid521 } from '@wix/bi-logger-groups-data/v2';

import {
  selectFeedPermissionsByGroup,
  selectGroup,
  selectGroupPrivacyStatus,
  selectIsGroupSecret,
} from 'store/selectors';

import { Box, type IBoxProps } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { IconButton } from 'wui/IconButton';
import { ShareDialog } from 'wui/ShareDialog';
import { Typography } from 'wui/Typography';
import { Tooltip } from 'wui/Tooltip';
import { Divider } from 'wui/Divider';
import {
  Share as ShareIcon,
  Search as SearchIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { GroupMembershipButton } from 'common/components/GroupMembership';
import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';

import { GroupActions } from '../GroupActions';
import { GroupSearch } from '../GroupSearch';
import { AddInviteMembers } from '../AddInviteMembers';

import classes from './GroupDetails.scss';

interface IGroupDetailsProps {
  groupId: string;
  search?: boolean;
}

export function GroupDetails(props: IGroupDetailsProps & IBoxProps) {
  const { groupId, search = true, className, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const group = useSelector(selectGroup(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(props.groupId));
  const { canViewPosts } = useSelector(selectFeedPermissionsByGroup(groupId));

  const [isShareOpen, setIsShareOpen] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug: group.slug },
    { absolute: true },
  );

  const canSearch =
    search && canViewPosts && experiments.enabled('specs.groups.GroupSearch');

  if (isMobile) {
    return (
      <Box
        gap="SP3"
        direction="vertical"
        className={cls(classes.root, classes.mobile, className)}
        {...rest}
      >
        <Box direction="vertical">
          <Typography
            as="h1"
            variant="p2-16"
            data-hook="group-name"
            className={classes.title}
          >
            {group.name}
            <RoleIcon role={group.role} className={classes.icon} />
          </Typography>
          <Typography as="div" variant="p2-14" className={classes.info}>
            <GroupInfo
              misc
              wired
              groupId={groupId}
              data-hook="group-details-info"
            />
          </Typography>
        </Box>

        <Box direction="vertical">
          <AddInviteMembers groupId={groupId} />

          <GroupMembershipButton
            fullWidth
            groupId={groupId}
            data-hook="group-membership-button"
            bi={groupsRequestJoinAGroup({
              group_id: props.groupId,
              origin: 'group_feed_top_banner_btn',
              type: (privacyStatus || '').toLowerCase(),
            })}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      gap="SP3"
      width="100%"
      verticalAlign="middle"
      align="space-between"
      className={cls(classes.root, className)}
      {...rest}
    >
      <Box direction="vertical">
        <Typography
          as="h1"
          variant="p2-16"
          data-hook="group-name"
          className={classes.title}
        >
          {group.name}
          <RoleIcon role={group.role} className={classes.icon} />
        </Typography>
        <Typography as="div" variant="p2-14" className={classes.info}>
          <GroupInfo wired groupId={groupId} data-hook="group-details-info" />
        </Typography>
      </Box>
      <Box gap="SP4">
        <Show if={canSearch}>
          <IconButton
            data-hook="search-button"
            icon={<SearchIcon />}
            className={classes.iconButton}
            onClick={handleOpenSearchDrawer}
          />
        </Show>

        <Show if={canSearch}>
          <Divider orientation="vertical" />
        </Show>

        <Box verticalAlign="middle" gap="SP3">
          <AddInviteMembers groupId={groupId} />

          <GroupMembershipButton
            groupId={groupId}
            data-hook="group-membership-button"
            bi={groupsRequestJoinAGroup({
              group_id: props.groupId,
              origin: 'group_feed_top_banner_btn',
              type: (privacyStatus || '').toLowerCase(),
            })}
          />

          <Hide if={isSecretGroup}>
            <Tooltip content={t('groups-web.a11y.share-group')}>
              <IconButton
                aria-label={t('groups-web.a11y.share-group')}
                data-hook="share-button"
                icon={<ShareIcon />}
                className={classes.iconButton}
                onClick={handleOpenShareModal}
              />
            </Tooltip>
          </Hide>

          <GroupActions groupId={groupId} data-hook="group-actions" />
        </Box>
      </Box>

      <ShareDialog
        isOpen={isShareOpen}
        data={{ url: groupUrl }}
        onClose={handleCloseShareModal}
        title={t('groups-web.group.actions.share.group')}
      />

      <GroupSearch
        groupId={groupId}
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </Box>
  );

  function handleCloseShareModal() {
    setIsShareOpen(false);
  }

  function handleOpenSearchDrawer() {
    bi.report(
      groupsSearchOpenSearchSrc111Evid521({
        groupId: props.groupId,
        origin: 'group_page',
      }),
    );
    setIsSearchOpen(true);
  }

  function handleOpenShareModal() {
    setIsShareOpen(true);

    bi.report(
      groupActionClick({
        origin: 'arrow',
        action: 'share',
        group_id: groupId,
      }),
    );
  }
}

GroupDetails.displayName = 'GroupDetails';
